$login-bg-color: rgba(255, 255, 255, 0.85);
#login {
    .message.error {
        background-color: rgba(255, 0, 0, 0.73);
        color: white;
        text-align: center;
    }
    .message.success {
        background-color: green;
        color: white;
        text-align: center;
    }
    .form-signin {
        @media screen and("min-width: 768px") {
            min-width: 50vh;
        }

        max-width: 370px;
        padding: 22px;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.85);
        margin-top: 10%;
        border-radius: 5px;
    }

    .vertical-center {
        min-height: 68vh;
        display: flex;
        align-items: center;
        @media screen and("max-width: 320px") {
            min-height: 60vh;
        }
    }

    .navbar-default {
        background-image: none;
        background-color: $login-bg-color;
        color: white;
        border: none;
        box-shadow: none;
        .navbar-nav > li > a:focus, .navbar-nav > li > a:hover {
            color: white;
            font-weight: 500;
        }
        .navbar-toggle {
            background-color: rgba(255, 255, 255, 0.85);
        }

    }
    .navbar-default .navbar-brand {
        color: white;
        font-size: 26px;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
    }
    .navbar-default .navbar-nav > li > a {
        color: #1d1d1d;
        font-weight: 100;
        font-size: 22px;
    }
    .form-signin-heading.text-center {
        font-size: 36px;
        margin-top: -2px;
        font-weight: 100;
        margin-bottom: 18px;
    }
    .form-signin .form-control {
        font-size: 16px;
        height: 45px;
        margin-bottom: 17px;
    }
    .enter-button {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 6px 16px;
        background-image: none;
        background-color: #2c6d2e;
        border: none;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active,
    .btn-primary.active.focus, .btn-primary.active:focus,
    .btn-primary.active:hover, .btn-primary:active.focus,
    .btn-primary:active:focus, .btn-primary:active:hover,
    .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus,
    .open > .dropdown-toggle.btn-primary:hover {
        color: #fff;
        background-color: #2c6d2e;
        border-color: none;
    }
    .password-reminder {
        font-size: 16px;
        color: #777;
        margin-top: 21px;
        margin-bottom: 3px;
        display: block;
    }
    #background-layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0);
        z-index: -1;
    }

    #footer-container {
        footer {
            background-color: transparent;
        }
        background-color: $login-bg-color;

        @media screen and("min-width: 1170px") {
            padding-right: 0px;
            padding-left: 0px;
            margin-right: auto;
            margin-left: auto;
            width: 100% !important;
            footer {
                width: 1170px;
                margin: 0 auto;
            }
        }
    }
    .login-background {
        background-color: $login-bg-color;
    }
    .contact-p {
        font-size: 17px;
        line-height: 26px;
    }
}

body#login {
    padding-bottom: 150px;
    background-image: url(/webroot/img/background_login.jpg);
    background-size: cover;
    > .container {
    }
}

.about-container {
    margin-top: 60px;
}

