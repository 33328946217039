$border-backround-color: #e3e3e3;
$bg-color: #F4F4F4;
$login-bg-color: rgba(255, 255, 255, 0.85);
body {
    font-family: "Open Sans";
    min-height: 100%;
    position: relative;
}

html {
    height: 100%;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

#admin {
    .pagination {
        margin: 0;
    }
    padding-bottom: 150px;
    /*
    * Breadcrumb
    */
    .message {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;

        &.success {
            background-color: $bg-color;
            color: white;
        }
        &.error {
            background-color: red;
            color: white;
        }
        &:before {
            content: "\e088";
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            margin-right: 10px;
        }
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        height: 40px;
        background-color: #e9e9e9;
        box-shadow: inset 0px 0px 37px rgba(0, 0, 0, 0.25);
        border-radius: 0;
        overflow: hidden;
        li {

            overflow: hidden;
            background-color: #2c6d2e;
            position: relative;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            padding-right: 30px;
            padding-left: 25px;
            margin-left: -10px;
            border-left: 1px solid white;
            transform: skewX(25deg);
            -moz-transform: skewX(25deg);
            a {
                transform: skewX(-25deg);
                -moz-transform: skewX(-25deg);
                -webkit-transform: skewX(-25deg);
                display: block;
            }
            /*
            &:not(:first-of-type){
              margin-left:-10px;
              border-left: 1px solid white;
              transform: skewX(25deg);
              -moz-transform: skewX(25deg);
              a{
                transform: skewX(-25deg);
                -moz-transform: skewX(-25deg);
                -webkit-transform: skewX(-25deg);
                display: block;
              }
            }
            */
            a {
                color: white;
            }
        }

        > li + li:before {
            display: none;
        }
    }

    .nav-tabs {
        margin-top: 25px;
        border: none;
        li {
            background-color: #2c6d2e;
            a {
                color: white;
                font-size: 20px;
                border: none;
                margin: 0;
                &:hover {
                    border-radius: 0;
                    color: black;
                }
            }
            &.active {
                background-color: white;

                a {
                    color: black;
                    border: none;
                }
            }
        }
    }
    .transporters {
        .nav-tabs {
            margin-bottom: 25px;
        }
    }
    .table {
        background-color: $bg-color;
    }
    .back-row {
        padding-top: 20px;
        padding-bottom: 20px;
        .back-button {
            background-color: #565656;
            color: white;
            text-shadow: none;
            text-transform: uppercase;
            .glyphicon.glyphicon-chevron-left {
                margin-right: 5px;
            }
            &:focus, &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }
    .badge {
        color: black;
        border-radius: 100px;
        border: 2px solid black;
        font-size: 28px;
        width: 40px;
        height: 40px;
        line-height: 28px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-right: 16px;
        background-color: transparent;

        &__grey {
            border-color: #cdcdcd;
            color: #cdcdcd;
        }

        &__green {
            border-color: #49d319;
            color: #49d319;
        }
    }
    legend {
        height: 45px;
        border: 0;
    }
    .year-label {
        font-weight: bold;
    }
    .year-interval {

    }
    .costs-row, .recruits-row {
        margin-top: 15px;
    }
    .submit-button {
        background-color: #41aa45;
        background-image: none;
        color: white;
        font-weight: 100;
        text-shadow: none;
        i {
            margin-right: 5px;
        }
    }
    .add-button {
        background-color: #565656;
        color: white;
        background-image: none;
        text-shadow: none;
        .glyphicon-plus {
            margin-right: 5px;
        }
    }
    #map-canvas-main {
        height: 400px;
    }
    .map-button {
        font-size: 20px;
        background-color: #565656;
        padding: 10px 15px;
        border-radius: 10px;
        background-image: none;
        color: white;
        text-shadow: none;
        font-weight: 300;
        img {
            height: 27px;
            margin-right: 10px;
            margin-top: -5px;
        }
    }
    .transporters-map-button {
        margin-left: 15px;
    }
    .new-location-button {
        margin-top: 23px;
    }
    .edit-button {
        background-color: #41aa45;
        color: white;
        background-image: none;
        text-shadow: none;
        &:before {
            content: "\270f";
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 3px;
        }
    }
    .delete-link {
        background-color: #565656;
        color: white;
        background-image: none;
        text-shadow: none;
        white-space: nowrap;
        padding: 6px 10px;
        border-radius: 5px;
        /* margin-top: 5px; */
        display: inline-block;
        background-color: #aa4040;

        &:before {
            content: "\e020";
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 3px;
        }
    }
    .suspend-link {
        color: white;
        background-image: none;
        text-shadow: none;
        white-space: nowrap;
        padding: 6px 10px;
        border-radius: 5px;
        display: inline-block;
        background-color: #debd38;

        :before {
            margin-right: 4px;
        }
    }
    .paginator-header a {
        font-size: 16px;
        color: #3c3c3c;
    }
    .paginator-header {
        line-height: 36px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #E1E1E1;
    }
    .costs-row.intervals {
        border-bottom: 1px solid #E1E1E1;
        border-top: 1px solid #E1E1E1;
        padding-top: 15px;
        margin-top: 10px;

        margin-bottom: 0px;
    }
    .costs-row.costs {
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #E1E1E1;
        margin-top: 0;
        div {
            line-height: 45px;
        }
    }
    #footer-container {
        display: none;
    }
    .row.costs-row {
        line-height: 33px;
    }
    .row.costs-row-header {
        line-height: 20px;
    }
    .recruit-delete {
        color: #3c3c3c;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
        margin-right: 15px;
        display: inline-block;

        .glyphicon-remove-sign {
            vertical-align: middle;
            margin-left: 5px;
        }
    }
    .index .row {
        margin: 0;

        &.header-row, &.header-row:hover {
            background-color: #e3e3e3;;
        }
        font-size: 16px;
        .check-column, .arrow-column {
            padding-bottom: 0;
            padding-top: 11px;
            .glyphicon {
                color: #c3c3c3;
                vertical-align: middle;
            }
            .glyphicon-ok-circle {
                font-size: 37px;
                &.finalized {
                    color: #4bd317;
                }
            }
            .glyphicon-menu-right {
                font-size: 16px;
            }
            &.arrow-column .glyphicon-menu-right {
                margin-top: 12px;
            }
        }
        .arrow-column {
            color: #c3c3c3;
            vertical-align: middle;
        }

    }
    .append-btn {
        //margin-bottom: 20px;
    }
    .index.columns {
        a {
            color: #333;
        }
    }
    .header-row {
        .glyphicon-triangle-bottom {
            display: none;
        }
        .glyphicon-triangle-top {
            display: none;
        }
        .asc {
            .glyphicon-triangle-top {
                display: inline-block;
                margin-left: 5px;
                top: 2px;
                width: 0;
            }
        }
        .desc {
            .glyphicon-triangle-bottom {
                display: inline-block;
                margin-left: 5px;
                top: 2px;
                width: 0;
            }
        }
    }
    .nav-tabs > li {
        float: left;
        margin-bottom: -1px;
        width: 25%;
        @media screen and("max-width:768px") {
            width: 100%;
            margin-bottom: 0;
            border-bottom: 1px solid grey;
        }
        text-align: center;
        font-weight: 100;
        margin-bottom: 20px;
        &.three-tabs {
            width: 33.33333333333%;
        }
    }
    hr {
        color: #d6d6d6;
        height: 1px;
        background-color: #d6d6d6;;
        margin-left: 15px;
        margin-right: 15px;
    }
    .location-save {
        margin-bottom: 27px;
    }
    .contact-row {
        margin-bottom: 15px;
        border-bottom: 1px solid #dedede;
    }
    .processor-contact-row {
        position: relative;
    }
    @media screen and(min-width: 992px) {
        .admin-map-div {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
        }
        .project-map-div {
            height: 400px;
        }
    }
    @media screen and(max-width: 992px) {
        .admin-map-div {
            height: 400px;
        }
    }
    .admin-map {
        margin-top: 0;
        height: 100%;
        @media screen and(max-with: 768px) {
            max-height: 400px;
        }
        //display:none;
    }
    .control-label {
        text-align: left;
    }
    .open-map-button {
        margin-bottom: 30px;
        background-color: #888888;
        background-image: none;
        color: white;
        text-shadow: none;
        .glyphicon-map-marker {
            margin-right: 5px;
        }
    }
    .contact-label {
        font-size: 20px;
    }
    .location-div {
        margin-bottom: 50px;
    }
    .grey-field {
        background-color: #f4f4f4;
        //padding: 10px 25px 10px 25px;
    }
    .company-name-div {
        font-size: 14px;
        .form-control {
            font-size: 24px;
        }
    }
    .form-control {
        font-size: 16px;
    }
    legend {
        font-size: 28px;
    }
    .limit-reached {
        color: #a4292b;
        font-weight: bold;
    }
    .modal-dialog {
        #map-canvas1 {
            height: 400px;
        }
    }
    .p-menge {
        font-size: 18px;
        /* color: #505050; */
        text-decoration: none;
        font-weight: normal;
        .menge {
            font-weight: bold;
        }
    }
}

.navbar-default {
    background-image: none;
    background-color: transparent;
    color: black;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    .navbar-nav > li > a:focus, .navbar-nav > li > a:hover {
        color: white;
        font-weight: 500;
    }
}

.navbar-text {
    margin-left: 15px;
}

.navbar-default .navbar-brand {
    color: black;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    padding: 0;
}

#logout-btn {
    color: white;
    background-color: #565656;
    padding: 5px 14px;
    font-size: 18px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: 10px;
    text-shadow: none;
}

.index {
    a, div.header-row {
        display: block;
        border-bottom: 1px solid #e3e3e3;
        > div {
            @media screen and (min-width: 990px) {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            font-size: 14px;
            line-height: 19px;
            @media screen and (max-width: 1200px) {
                font-size: 13px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @media screen and (max-width: 800px) {
                font-size: 12px;
            }
            @media screen and (max-width: 480px) {
                font-size: 11px;
            }
            p {
                margin: 0;
            }
        }
    }
    .row:not(.header-row) a:hover, .row:not(.header-row):hover {
        background-color: white;
    }

}

#footer-container {
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    footer {
        height: 60px;
        background-color: $border-backround-color;
        .copyright {
            @media screen and("max-width: 507px") {
                text-align: -webkit-center;
            }
            line-height: 60px;
            text-align: left;
            margin: 0 20px 0 20px;
        }
        #app-store-span {
            float: right;
            img {
                height: 45px;
                margin-left: 20px;
            }
            @media screen and("max-width: 507px") {
                margin-right: 10%;
                span {
                    display: block;
                }
                #laden-app {
                    text-align: center;
                }
            }
            @media screen and("min-width: 768px") {
                #app-store-logo {
                    margin-left: 0px;
                }
            }
        }
    }
}

.container.greycontainer, #admin > .container {
    background-clip: content-box;
    background-color: $bg-color;
}

.share-row {
    background-color: #dadada;
    padding-bottom: 45px;
    padding-top: 30px;
    margin-bottom: 30px;

}

