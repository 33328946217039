.btn.btn-default.append-btn {
  background-image: none;
  background-color: #888888;
  color: white;
  font-weight: 100;
  font-size: 14px;
  text-shadow: none;
  img {
    margin-right: 5px;
  }
}

.angular-google-map-container {
  height: 250px;
}

#logo {
  height: 50px;
  margin-left: 15px;
}

.bold {
  font-weight: bold;
}

.contact-person {
  background-color: #FFF;
  fieldset {
    legend {
      font-size: 18px !important;
      height: 41px !important;
      line-height: 41px;
      margin-bottom: 0;
      .glyphicon {
        color: #E0E0E0;
        font-size: 22px;
        padding-top: 9px;
      }
    }
  }
}

.sub-menu {

  padding-left: 15px;
  padding-right: 15px;

  li {
    margin-left: -1px;
    width: 100%;
    max-width: 155px;
    display: block;
    float: left;
    font-weight: bold;
    border: 1px solid #565656;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a {
      display: block;
      width: 100%;
      padding: 12px 0;
      text-decoration: none;
      color: #565656;
    }
    &.active, &:hover {
      a {
        color: #fff;
        background-color: #565656;
      }
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  &.sub-menu-transporters li {
    width: 50%;
    max-width: 50%;
  }

  &:after {
    content: '';
    clear: both;
  }
}

.panel .sub-menu li a {
  padding: 3px 0;
}

.btn-add-destination {
  color: #fff;
  padding: 12px 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #000;
  .glyphicon {
    margin-right: 5px;
  }
  &:hover {
    background-color: transparent;
    color: #000;
  }
}

#dialog-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  &.dialog-shown {
    display: block;
  }

  #dialog-message-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 25px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    width: 370px;
    height: 220px;
    z-index: 11;
  }

  #dialog-message {
    height: 135px;
    width: 320px;
    vertical-align: middle;
    display: table-cell;
    font-size: 18px;
    text-align: center;
  }

  #dialog-btn-wrapper {
    .btn {
      width: 90px;
      border-radius: 5px;
      border: 1px solid #40AA45;
      background-color: #40AA45;
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: #309935;
        border-color: #309935;
      }
    }

    .btn + .btn {
      background-color: #565656;
      margin-left: 134px;
      border-color: #565656;
      &:hover {
        background-color: #363636;
        border-color: #363636;
      }
    }
  }
}

.col-xs-offset-right-12 {
  margin-right: 100%;
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}

.col-xs-offset-right-9 {
  margin-right: 75%;
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}

.col-xs-offset-right-6 {
  margin-right: 50%;
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}

.col-xs-offset-right-3 {
  margin-right: 25%;
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}

.col-xs-offset-right-0 {
  margin-right: 0;
}

@media (min-width: 768px) {

  .col-sm-offset-right-12 {
    margin-right: 100%;
  }
  .col-sm-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-sm-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-sm-offset-right-9 {
    margin-right: 75%;
  }
  .col-sm-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-sm-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-sm-offset-right-6 {
    margin-right: 50%;
  }
  .col-sm-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-sm-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-sm-offset-right-3 {
    margin-right: 25%;
  }
  .col-sm-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-sm-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-sm-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .col-md-offset-right-12 {
    margin-right: 100%;
  }
  .col-md-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-md-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-md-offset-right-9 {
    margin-right: 75%;
  }
  .col-md-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-md-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-md-offset-right-6 {
    margin-right: 50%;
  }
  .col-md-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-md-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-md-offset-right-3 {
    margin-right: 25%;
  }
  .col-md-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-md-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-md-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-offset-right-12 {
    margin-right: 100%;
  }
  .col-lg-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-lg-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-lg-offset-right-9 {
    margin-right: 75%;
  }
  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-lg-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-lg-offset-right-6 {
    margin-right: 50%;
  }
  .col-lg-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-lg-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-lg-offset-right-3 {
    margin-right: 25%;
  }
  .col-lg-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-lg-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-lg-offset-right-0 {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .sub-menu li {
    margin: 0 auto;
    float: none;
    margin-top: -1px;
    &:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
    }
  }
}

.hide {
  display: none;
}

.vertical-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.vertical-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.vertical-margin-0 {
  margin-left: 0;
  margin-right: 0;
}

.last-row {
  padding-bottom: 50px;
}

.select2-container {
  width: 100% !important;
  input.select2-search__field {
    border: none !important;
  }
}

.agb-span {
  margin-right: 50px;
  span {
    margin-right: 15px;
  }
}

/* tables */
table.tablesorter {
  font-family: arial;
  background-color: #CDCDCD;
  margin: 10px 0pt 15px;
  font-size: 8pt;
  width: 100%;
  text-align: left;
}

table.sortableLieferscheinTable, table.sortableTasksTable {
  thead tr .header {
    background-image: url(/img/bg.gif);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
  }
  tbody tr {
    background-color: #FFF;
    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
  }
  tbody td {
    color: #3D3D3D;
    padding: 4px;
    background-color: transparent;
    vertical-align: top;
  }
  tbody tr.odd td {
    background-color: #F0F0F6;
  }
  thead tr .headerSortUp {
    background-image: url(/img/asc.gif);
  }
  thead tr .headerSortDown {
    background-image: url(/img/desc.gif);
  }
  thead tr .headerSortDown, thead tr .headerSortUp {
    background-color: #8dbdd8;
  }
}

.noUi-handle {
  &.noUi-handle-lower {
    cursor: w-resize;
  }
  &.noUi-handle-upper {
    cursor: e-resize;
  }
}

.noUi-connect {
  background: #2c6d2e;
}

.hidden-timestamp {
  display: none;
}

.slider-date {
  display: block;
}

.slider-row {
  margin-bottom: 2rem;
  margin-top: 1rem;
  span {
    margin-bottom: 1rem;
  }
  .slider-button {
    height: 50px;
    font-size: 14px;
  }
}

.paginator {
  text-align: center;
  margin-top: 1rem;
  .pagination {
    & > li:last-child > a, & > li:last-child > span {
      float: right;
    }
    & > .active > a, .pagination > .active > a:focus, & > .active > a:hover, & > .active > span, & > .active > span:focus, & > .active > span:hover {
      background-color: #2c6d2e;
      color: #ffffff !important;
    }
  }
}
