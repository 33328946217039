$secondary-color: #565656;
$bg-color: #F4F4F4;
$ternary-color: #E0E0E0;
$nav-border-radius: 10px;
$nav-padding: 10px 15px;

.bullet {
  //border: 2px solid #272727;
  font-weight: bold;
  border-radius: 24px;
  height: 48px;
  padding-left: 2px;
  min-width: 48px;
  line-height: 44px;
  font-size: 28px;
  display: inline-block;
  text-align: center;
  margin-right: 17px;
}

.image-stretch {
  width: 100%;
}

.project-bg {
  background-color: $bg-color;
}

.waybill-input {
  height: 35px;
  width: 100%;
}

#admin {
  .grey-child-rows {
    > .row {
      margin-right: 0;
      margin-left: 0;
      background-color: $bg-color;
    }
  }
}

#admin .tasks-container {
  h4 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  legend {
    margin-top: 20px;
  }
  .row.add-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
  .review-text {
    font-size: 14px;
    line-height: 28px;
  }

  .badge {
    background-color: transparent;
  }
  .review-value {
    font-weight: bold;
  }

  .task-header1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px
  }

  .task-header2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .route-row {
    margin: 30px 0 30px 0;
  }
  .transit-header {
    font-size: 28px;
    line-height: 72px;
  }
}

#admin .review-container {
  > .row {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0px;
    margin-right: 0px;
    background-color: $bg-color;
  }
  legend {
    margin-top: 35px;
  }
  .review-text {
    font-size: 13px;
    line-height: 28px;
  }

  .badge {
    background-color: transparent;
  }
  .review-value {
    font-weight: bold;
  }

  .task-header1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px
  }

  .task-header2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .route-row {
    margin: 30px 0 30px 0;
  }
  .transit-header {
    font-size: 28px;
    line-height: 72px;
  }
}

.dest-inputs {
  padding-bottom: 30px;
  border-bottom: 1px solid $ternary-color;
  margin-bottom: 30px;
  .form-group {
    margin-bottom: 7px;
    label {
      padding-top: 0;
      font-weight: normal;
    }
  }
  .row-inputs {
    margin-bottom: 23px;
  }
  .row-remove {
    margin-bottom: 15px;
  }
}

.bullet-container {
  margin-bottom: 20px;
}

.bullet-title {
  display: inline-block;
  font-size: 28px;
}

@media screen and (max-width: 640px) {
  .bullet-title {
    font-size: 15px;
  }
}

.row-no-margin {
  margin-left: 0;
  margin-right: 0;
}

#projects {
  &.pdf-layout {
    td {
      border: 1px solid grey;
      padding: 0px 0 10px 0;
      background-color: #00CC00;
      line-height: 20px;
    }
  }
  .row-header {
    margin-bottom: 30px;
  }

  .row-submit {
    margin: 30px 0;
  }

  input {
    border: 1px solid #B7B7B7;
  }

  .nav {
    background-color: $bg-color;
  }
  .nav-tabs li.active a {
    background-color: #F4F4F4 !important;
  }
  .add-button-in-nav-row {
    .append-btn {
      font-size: 20px;
      background-color: #565656;
      padding: $nav-padding;
      border-radius: $nav-border-radius;
    }
  }
  .group {
    background-color: #fff;
  }

  .btn-activate, .btn-remove {
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    line-height: 24.5px;
  }

  .btn-remove {
    border: 1px solid #AA4040;
    background-color: #AA4040;
    &:hover {
      background-color: #883030;
      border-color: #883030;
    }
  }

  .btn-activate {
    border: 1px solid #40AA45;
    background-color: #40AA45;
    &:hover {
      background-color: #309935;
      border-color: #309935;
    }
  }

  .btn {

    .btn-title, .btn-icon {
      display: block;
      float: left;
    }

    .btn-icon {
      margin-right: 5px;
      font-size: 20px;
      margin-top: 2px;
    }
  }

  .add-task-button {
    cursor: pointer;
    border-radius: 3px;
    padding: 7px 0;
    font-weight: bold;
    background-color: #888888;
    color: #fff;
    text-align: center;
    margin: 0 15px;
  }
  .panel {
    .add-task-button {
      margin: 15px 0;
    }
  }
  .tree-group {
    background-color: $bg-color;
  }

  .add-task-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
    line-height: 15px;
    font-size: 16px;
    padding-left: 1px;
    text-align: center;
    display: inline-block;
  }

  .add-task-title {
    display: inline-block;
  }

  label {
    margin-bottom: 0;
    line-height: 34px;
  }

  .task-volume-inputs {
    background-color: #2B6D26;
    color: #fff;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 15px;
    .row {
      padding: 15px 0 7px 0;
    }
    .row + .row {
      padding: 0 0 15px 0;
    }
  }

  .angular-google-map-container {
    height: 301px;
  }

  .form-control {
    border-radius: 2px;
  }

  .order-wrapper {
    .row + .row {
      padding-top: 15px;
    }
  }

  .location-wrapper-row {
    position: relative;
    .row-location-input {
      margin-bottom: 15px;
    }
  }

  .overall-amount-wrapper {
    width: 395px;
    margin-left: -40px;
    padding: 15px;
    background-color: $secondary-color;
    color: #fff;
    font-weight: bold;
    line-height: 34px;
    .row + .row {
      margin-top: 7px;
    }
  }

  .project-input-block {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid $ternary-color;
  }

  .date-group {
    display: inline-block;
    width: 240px;
    label {
      font-weight: normal;
    }
  }

  .date-group-title {
    line-height: 34px;
    vertical-align: top;
    font-weight: bold;
  }

  .task-type-title {
    line-height: 34px;
    font-weight: bold;
  }

  .row-task-type {
    margin-bottom: 60px;
  }

  select {
    font-size: 14px;
    height: 34px;
    background-color: #F8F8F8;
    background-position: 85% 15%;
    &.form-control:not([disabled]) {
      background-color: white;
    }
  }

  .remove-icon-container {
    text-align: right;
    .remove-icon {
      border: 1px solid #AA4040;
      background-color: #AA4040;
      border-radius: 5px;
      width: 35px;
      color: #fff;
      height: 35px;
      &:hover {
        background-color: #882020;
      }
    }
  }

  .row-btn-container {
    padding: 15px 0;
    margin: 0;
    background-color: $bg-color;
    .pull-right {
      margin-right: 15px;
    }
  }

  .glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: wspin .7s infinite linear;
  }

  .project-errors {
    display: none;
    background-color: #AA4040;
    border-radius: 5px;
    border: 1px solid #AA4040;
    margin: 15px 15px 30px 15px;
    color: #fff;
    &.error-shown {
      display: block;
    }
    ul {
      margin-top: 10px;
    }
    .glyphicon {
      margin-top: -1px;
      &:hover {
        background-color: #883030;
        color: #fff;
      }
    }
  }

  .notification-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(187, 187, 187, 0.4);
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
    .status {
      margin-left: 9px;

      .glyphicon {
        font-size: 30px;
      }
      p {
        margin-left: 10px;
        line-height: 30px;
        margin-bottom: 0;
      }
      &.status-saved {
        .glyphicon {
          color: #40AA45;
        }
      }
      &.status-error {
        .glyphicon {
          color: #AA4040;
        }
      }

      display: none;

      &.status-shown {
        display: block;
      }
    }
    &.status-shown {
      display: block;
    }
    &.status-layer {
      display: block;
      // bottom: 0;
    }
  }
  .nofancy-link a {
    color: inherit;
  }
  .saved-panel {
    background-color: #e3f7e3;
  }
  .not-saved-panel {
    background-color: #ffe7ee;
  }
  .processor-row {
    .processor-company-name {
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .processor-location-row {
      padding-left: 20px;
      .processor-location {
        font-size: 16px;
      }
    }

  }
}
