.container.transporters {
  background-color: $bg-color;
}

#admin .transporters.index {
  .row {
    margin: 0;
  }
}

#admin .legend-row {
  margin-top: 30px;
}

#tasks .row-btn-container {
  padding: 15px 0;
}

#admin td.transit-badge {
  text-align: center;
  span.badge {
    margin: 0;
  }
}